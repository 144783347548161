import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Button, Card, CardMedia, CardContent, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, InputAdornment } from '@mui/material';
import Rating from '@mui/material/Rating';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { cardPaymentSuccess, createCardForCustomerData, getSingleCardDetailsDirectly } from '../../redux/actions/cardsForCustomerActions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllCardSuggestionData } from '../../redux/actions/getAllCardSuggestionActions';
import axios from 'axios';
import { createDigitalCardForCustomerData } from '../../redux/actions/digitalCardActions';
import countries from "../../components/countries.json"

function InvitationCard() {


  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDarkMode = theme.palette.mode === "dark";

  // fetch data
  const params = useParams();
  const [id, setId] = useState(params.card_id);
  const [selectedImage, setSelectedImage] = useState("");

  const handleImageClick = (url) => {
    setSelectedImage(url);
  };

  useEffect(() => {
    setId(params.card_id);
  }, [params.card_id, id]);


  const cardField = useSelector((state) => state?.cardforCustomer?.cardForCustomerdata?.data);


  const [cardDetails, setCardDetails] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  let [customerName, setCustomerName] = useState('');
  let [customerMobile, setCustomerMobile] = useState('');
  let [customerEmail, setCustomerEmail] = useState('');

  useEffect(() => {
    const fetchCardDetails = async () => {
      let cardData = await getSingleCardDetailsDirectly(params.card_id, params.group_path, params.group_type);
      if (cardData) {
        setCardDetails(cardData.data);
        handleImageClick(cardData.data.card_samples[0].url);
      }

    };

    fetchCardDetails();
  }, [dispatch]);

  const cardSuggestion = useSelector((state) => state?.getAllCardSuggestions?.getAllCardSuggestion?.data);

  const limit = 50;



  useEffect(() => {
    const fetchAllCardSuggestion = async () => {
      await dispatch(getAllCardSuggestionData(params.group_path, params.group_type, limit));

    };
    fetchAllCardSuggestion();
  }, [dispatch])



  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', { month: 'long' });
    const year = date.getFullYear();
    const weekday = date.toLocaleString('en-US', { weekday: 'long' }); 
  
    return `${weekday}, ${day} ${month} ${year}`;
  };
  
  // Example usage
  console.log(formatDate(new Date())); 
  

  // async function ImageUploadToSignedUrl(field, event) {
  //   console.log(event);
  //   const fileToUpload = event.target.files[0];
  //   console.log('1. SelectedFile: ', fileToUpload);

  //   const filesArray = event.target.files;
  //   for (let index = 0; index < filesArray.length; index++) {
  //     const element = filesArray[index];
  //     console.log(index, element);

  //     const body = {
  //       file_name: `${element.name.replace(/[^a-zA-Z0-9.]/g, "")}`,
  //       file_type: element.type
  //     }
  //     console.log('Body: ', body);

  //     let presignedUrlData = await getSignedUrlDirectly(body);
  //     console.log('presignedUrlData: ', presignedUrlData);

  //     try {
  //       const response = await axios.put(presignedUrlData.signedRequest || '', element, {
  //         headers: {
  //           'Content-Type': element.type
  //         }
  //       });

  //       if (response.status === 200) {
  //         console.log('Upload successful:', response);
  //         field['field_value'] = presignedUrlData.url;
  //       } else {
  //         console.error('Upload failed:', response.statusText);
  //       }
  //     } catch (error) {
  //       console.error('Error during upload:', error);
  //     }
  //   }
  // }

  // const [message, setMessage] = useState('');

  const handleFileUpload = async (field, event) => {
    const formData = new FormData();
    formData.append('file', event.target.files[0]);

    try {
      const response = await axios.post('https://account.rajoffset.com/web/upload/v1/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      field['field_value'] = response.data.data;

    } catch (error) {

    }
  };

  const handleFieldChange = (field, event) => {
    let newValue = event.target.value;
    let validation = field.field_validation;

    if (field.field_type === 'file') {
      handleFileUpload(field, event);
    }

    if (field.field_type === 'date') {
      // Example Input Date: 2025-02-05
      const dateValue = new Date(newValue);
      console.log("Date Value", dateValue);
      if (!isNaN(dateValue)) {
        const newFormat = formatDate(dateValue);
        console.log("New Format", newFormat);
        field['field_value'] = newFormat;
      } else {
        console.error("Invalid date format");
      }
    }

    if (field.field_type === 'time') {
      const timeParts = newValue.split(':');
      let hours = parseInt(timeParts[0], 10);
      let minutes = timeParts[1];
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert 24-hour to 12-hour format
      const newTime = `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
      console.log("new Time", newTime);
      field['field_value'] = newTime;
    }

    if (validation === 'STRING_80') {
      if (newValue.length <= 400) {
        field['field_value'] = newValue;
      } else {
        console.error("The value should not be greater than 80 characters");
      }
    }
    else if (validation === 'EMAIL') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(newValue)) {
        field['field_value'] = newValue;
      } else {
        console.error("Invalid email format");
      }
    }
    else if (validation === 'NUMBER') {
      if (!isNaN(newValue)) {
        field['field_value'] = newValue;
      } else {
        console.error("Number is not valid");
      }
    }
    else if (validation === 'DATE_FORWARD') {
      const inputDate = new Date(newValue);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (inputDate <= today) {
        field['field_value'] = newValue;
      } else {
        console.error('The date cannot be later than today');
      }
    }
    
    else {
      field['field_value'] = newValue;
    }

    validateForm();
    console.log("Updated Field:", field);
  };

  //   useEffect(() => {
  //     if (cardDetails) {
  //         validateForm();
  //     }
  // }, [cardDetails]);

  const validateForm = () => {
    const allFieldsFilled = cardDetails.card_fields.every(field => field.field_value);
    console.log("All fields filled", allFieldsFilled);
    setIsFormValid(true);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function initiateRazorPayPayment(amount, currency, order_name, order_description, rzpOrderId, rzpKey, customer_name, customer_email, customer_mobile, entry_id) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }


    const options = {
      key: rzpKey, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: order_name,
      description: order_description,
      // image: { logo },
      order_id: rzpOrderId,
      handler: async function (response) {
        console.log("Razorpay Response", response);
        const data = {
          entry_id: entry_id,
          orderCreationId: rzpOrderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };

        let paymentReconcileData = await cardPaymentSuccess(entry_id, data);


        console.log("Payment Success", data);
        if (paymentReconcileData && paymentReconcileData.data) {
          switch (paymentReconcileData.data.action) {
            case 'payment_success_screen':
              navigate(`/payment-success-screen`);
              break;
            case 'payment_waiting_screen':
              /** Navigate to payment waiting screen*/
              navigate(`/payment-waiting-screen`);
              break;
            case 'payment_failed_screen':
              /**navigate to payment failed screen */
              navigate(`/payment-failed-screen`);
              break;
            default:
              /** Navigate to login */
              navigate(`/card-submitted-successfully`);
              break;
          }
        }
      },
      prefill: {
        name: customer_name,
        email: customer_email,
        contact: customer_mobile,
      },
      // notes: {
      //   address: "Soumya Dey Corporate Office",
      // },
      theme: {
        color: "#61dafb",
      },
      "modal": {
        "ondismiss": function () {
          navigate(`/payment-failed-screen`);
        }
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const formatTime = (timeString) => {
    if (!timeString) return "";

    const timeParts = timeString.split(':');
    let hours = parseInt(timeParts[0], 10);
    let minutes = timeParts[1];
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;
    return `${String(hours).padStart(2, '0')}:${minutes} ${ampm}`;
  };

  const handleSubmit = async () => {
    let body = {
      card_name: cardDetails.card_name,
      card_description: cardDetails.card_description,
      card_fields: cardDetails.card_fields.map(({ field_name, id, field_value, field_type }) => ({
        field_name,
        id,
        field_value: field_type === 'date'
          ? (field_value ? formatDate(new Date(field_value)) : "")
          : field_type === 'time'
            ? (field_value ? formatTime(field_value) : "")
            : (field_value || "")
      })),

      customer_name: customerName,
      customer_mobile: customerMobile,
      customer_email: customerEmail,
      country_code: selectedCountry?.dial_code,
    };

    if (params.group_type === 'digital invitation') {
      let rsp = await createDigitalCardForCustomerData(params.card_id, body);
      console.log("RSP", rsp);
      //   {
      //     "status": "success",
      //     "message": "Card created successfully",
      //     "data": {
      //         "action": "payment_screen",
      //         "attempt_id": "5ad5a8e8-86b6-4d7d-9284-90d58761f68d",
      //         "orderId": "order_P4mNhD8TZrQZnl",
      //         "amount": 100,
      //         "currency": "INR",
      //         "receipt": "5ad5a8e8-86b6-4d7d-9284-90d58761f68d",
      //         "status": "created",
      //         "attempts": 0,
      //         "created_at": 1728005307,
      //         "offer_id": null,
      //         "key": "rzp_live_L3aKyZWl8SRyYR"
      //     }
      // }
      // await dispatch(fetchAllInvitationCards(cardId));

      if (rsp && rsp.data) {
        switch (rsp.data.action) {
          case 'payment_screen':
            /** Navigate to payment screen */
            await initiateRazorPayPayment(
              rsp.data.amount,
              rsp.data.currency,
              cardDetails.card_name,
              cardDetails.card_description,
              rsp.data.orderId,
              rsp.data.key,
              customerName,
              customerEmail,
              customerMobile,
              rsp.data.attempt_id
            );

            break;
          case 'login_screen':
            /** Navigate to Login Screen */
            navigate(`/card-submitted-successfully`);
            break;
          default:
            /** Navigate to login */
            navigate(`/card-submitted-successfully`);
            break;
        }
      }
    } else {
      let rsp = await createCardForCustomerData(params.card_id, body);

      if (rsp && rsp.data) {
        switch (rsp.data.action) {
          case 'payment_screen':
            /** Navigate to payment screen */
            await initiateRazorPayPayment(
              rsp.data.amount,
              rsp.data.currency,
              cardDetails.card_name,
              cardDetails.card_description,
              rsp.data.orderId,
              rsp.data.key,
              customerName,
              customerEmail,
              customerMobile,
              rsp.data.attempt_id
            );

            break;
          case 'login_screen':
            /** Navigate to Login Screen */
            navigate(`/card-submitted-successfully`);
            break;
          default:
            /** Navigate to login */
            navigate(`/card-submitted-successfully`);
            break;
        }
      }
    }


  };

  const [payNowOpen, setpayNowOpen] = useState(false);

  const handleOpenPayNowDialog = () => setpayNowOpen(true);
  const handleClosePayNowDialog = () => setpayNowOpen(false);

  const handlePayNow = () => {
    handleSubmit(cardDetails);
    handleClosePayNowDialog(); // Close the dialog after payment
  };

  const handleOpenFullImageClick = () => {
    // Open image in full screen
    const imgWindow = window.open(selectedImage, '_blank');
    imgWindow.focus();
  };

  //countries change
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedPhoneLength, setSelectedPhoneLength] = useState(countries[0].phoneLength);

  const handleCountryChange = (event) => {
    const country = countries.find((c) => c.dial_code === event.target.value);
    setSelectedCountry(country);
    setSelectedPhoneLength(country?.phoneLength);
  }



  return (
    <>
      <Box sx={{ padding: 2, marginX: 'auto', maxWidth: '1200px', marginTop: 5, overflowX: 'hidden' }}>

        {cardDetails && cardDetails?.card_samples && cardDetails?.card_samples.length > 0 ? (
          <>
            <Typography sx={{ textAlign: 'left', fontSize: '0.875rem', fontWeight: 650, fontFamily: 'inherit' }}>
              {params.group_path} Digital Invitation Details
            </Typography>
            <Typography sx={{ fontSize: '1.8rem', textAlign: 'left', fontFamily: 'inherit' }}>
              {cardDetails.card_name}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: 2 }}>
              <Rating value={5} readOnly />
            </Box>

            <Grid container spacing={2} justifyContent="center">
              {/* Left side image and 4x1 grid */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <img
                      src={selectedImage}
                      alt="Logo"
                      onClick={handleOpenFullImageClick}
                      className='animate__animated animate__pulse'
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {cardDetails.card_samples.map((sample, index) => (
                        <Grid item xs={2} key={index}>
                          <img
                            src={sample.url}
                            alt={`Sample ${index + 1}`}
                            style={{
                              maxHeight: '100%',
                              maxWidth: '100%',
                              objectFit: 'contain',
                            }}
                            onClick={() => handleImageClick(sample.url)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Right side form */}
              <Grid item xs={12} md={5} sx={{ padding: 2, borderRadius: '8px', backgroundColor: '#E2EAF4' }}>
                {cardDetails && cardDetails?.card_fields && cardDetails?.card_fields?.length > 0 ? (
                  <>

                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'left' }}>
                        Creative {params.group_path} Invitation Card Instant Download
                      </Typography>
                      <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>
                        Fill in below details to get your e-card
                      </Typography>
                    </Box>

                    {cardDetails?.card_fields?.map((field, index) => (
                      <Box
                        key={index}
                        sx={{
                          backgroundColor: theme.palette.mode === "dark" ? '#333' : '#eceff1',
                          padding: 2,
                          color: theme.palette.text.primary
                        }}
                      >
                        {
                          field.field_type === 'string' ?
                            <TextField
                              fullWidth
                              name={field?.field_name}
                              id={field?.id}
                              label={field?.field_placeholder}
                              placeholder={field?.field_placeholder}
                              type={field?.field_type}
                              defaultValue={field.field_default}
                              required={field.field_required}
                              onChange={event => handleFieldChange(field, event)}
                              InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                        }

                        {
                          field.field_type === 'file' ?
                            <TextField
                              fullWidth
                              name={field?.field_name}
                              id={field?.id}
                              label={field?.field_placeholder}
                              placeholder={field?.field_placeholder}
                              type={field?.field_type}
                              defaultValue={field.field_default}
                              required={field.field_required}
                              onChange={event => handleFieldChange(field, event)}
                              InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                        }

                        {
                          field.field_type === 'date' ?
                            <TextField
                              fullWidth
                              name={field?.field_name}
                              id={field?.id}
                              label={field?.field_placeholder}
                              placeholder={field?.field_placeholder}
                              type={field?.field_type}
                              defaultValue={field.field_default}
                              required={field.field_required}
                              onChange={event => handleFieldChange(field, event)}
                              InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                        }
                        {
                          field.field_type === 'time' ?
                            <TextField
                              fullWidth
                              name={field?.field_name}
                              id={field?.id}
                              label={field?.field_placeholder}
                              placeholder={field?.field_placeholder}
                              type={field?.field_type}
                              defaultValue={field.field_default}
                              required={field.field_required}
                              onChange={event => handleFieldChange(field, event)}
                              InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                        }
                        {
                          field.field_type === 'option' ? (
                            <FormControl fullWidth required={field.field_required}>
                              <InputLabel id={`label-${field.id}`}>{field?.field_placeholder}</InputLabel>
                              <Select
                                labelId={`label-${field.id}`}
                                name={field?.field_name}
                                id={field?.id}
                                defaultValue={field?.field_default || 'xyz'}
                                onChange={event => handleFieldChange(field, event)}
                              >
                                {field?.field_options.map(option => (
                                  <MenuItem key={option?.id} value={option?.option_value}>
                                    {option?.option_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : null
                        }

                        {
                          field.field_type === 'number' ?
                            <TextField
                              fullWidth
                              name={field?.field_name}
                              id={field?.id}
                              label={field?.field_placeholder}
                              placeholder={field?.field_placeholder}
                              type={field?.field_type}
                              defaultValue={field.field_default}
                              required={field.field_required}
                              onChange={event => handleFieldChange(field, event)}
                              InputLabelProps={{ shrink: true }}
                            ></TextField> : null
                        }

                      </Box>
                    ))}
                    <Box
                      sx={{
                        backgroundColor: theme.palette.mode === "dark" ? '#333' : '#eceff1',
                        padding: 2,
                        color: theme.palette.text.primary,

                      }}
                    >
                      <TextField
                        fullWidth
                        label="Customer Name"
                        name="customer_name"
                        placeholder='Customer Name'
                        onChange={event => setCustomerName(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                        required
                      />
                      <FormControl fullWidth>
                        <TextField
                          fullWidth
                          label="WhatsApp Number (to get download link)"
                          name="customer_mobile"
                          placeholder='WhatsApp Number'
                          onChange={event => setCustomerMobile(event.target.value)}
                          InputLabelProps={{ shrink: true }}
                          required
                          sx={{ mt: 2 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Select
                                  value={selectedCountry?.flag}
                                  onChange={handleCountryChange}
                                  renderValue={() => (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <img
                                        src={selectedCountry?.flag}
                                        alt={selectedCountry?.name}
                                        style={{ width: 24, height: 16, marginRight: 8 }}
                                      />
                                      {selectedCountry?.dial_code}
                                    </div>
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        boxShadow: 'none',
                                        border: 'none',
                                        mt: 1,
                                        "& .MuiMenuItem-root": {
                                          padding: '8px 16px',
                                        }
                                      }
                                    }
                                  }}
                                  sx={{
                                    border: 'none',
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none',
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                      border: 'none',
                                    },
                                  }}
                                >
                                  {countries.map((country) => (
                                    <MenuItem key={country.dial_code} value={country.dial_code}>
                                      <img
                                        src={country.flag}
                                        alt={country.name}
                                        style={{ width: 24, height: 16, marginRight: 8 }}
                                      />
                                      {country.name} ({country.dial_code})
                                    </MenuItem>
                                  ))}
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>

                      <TextField
                        fullWidth
                        label="Customer Email"
                        name="customer_email"
                        placeholder='Customer Email'
                        onChange={event => setCustomerEmail(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                        required
                        sx={{ mt: 2 }}
                      />
                    </Box>
                    <Box sx={{
                      padding: 2,
                    }}>
                      <Typography sx={{ fontWeight: 'bold' }}>
                        Final Total
                      </Typography>
                      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' }, gap: 1, marginBottom: { md: 1 } }}>
                        <Box
                          bgcolor="#d32f2f"
                          color="white"
                          px={0.5}
                          py={0.5}
                          borderRadius="4px"
                          display="inline-block"
                          sx={{ fontSize: '0.725rem' }}
                        >
                          {Math.round(((cardDetails?.original_price - cardDetails?.sale_price) / cardDetails?.original_price) * 100)}% off
                        </Box>

                        <Typography sx={{ fontSize: '0.825rem', color: 'red', fontWeight: 'bold' }}>
                          Diwali Sale
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5, flexDirection: { xs: 'column', md: 'row' } }}>
                        <Typography variant="h6" color="textPrimary" fontWeight="bold" sx={{ fontSize: '1.25rem', lineHeight: 1 }}>
                          {Math.floor(cardDetails.sale_price) === 0 ? 'Free' : `₹${Math.floor(cardDetails.sale_price)}`}
                        </Typography>

                        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through', fontSize: '0.875rem' }}>
                          M.R.P: ₹{cardDetails?.original_price.toLocaleString()}
                        </Typography>
                      </Box>
                    </Box>


                    <Button
                      variant="contained"
                      color="error"
                      sx={{ marginTop: 2, width: '100%' }}
                      onClick={handleOpenPayNowDialog}
                      disabled={!isFormValid || customerMobile.length !== selectedPhoneLength}
                    >
                      Submit & Complete Payment
                    </Button>

                  </>
                ) : (
                  <Typography>This card is restricted. To get this card whatsapp us on 876514002 or email us at support@awesomeinnovators.com</Typography>
                )}


              </Grid>
            </Grid>
          </>
        ) : (
          <Typography>Please wait while we are fetching card details...</Typography>
        )}

        {/* Main Heading */}



        <Box p={4}>
          {/* Description Section */}
          <Box mb={4}>
            <Typography variant="h6" gutterBottom>
              Description
            </Typography>
            <Typography variant="body1" color="initial" sx={{ textAlign: 'left', fontWeight: 750, fontFamily: 'inherit' }}> 🔶Specification of Creative engagement invitation card template:</Typography>
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginTop: '10px',
                border: '1px solid #ddd',
              }}
            >
              <tbody>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Description:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Creative {params.group_path} invitation card design
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Delivery Timeline:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Download option available after successful payment
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Ideal For:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Share Indian engagement invitation template designs on WhatsApp, Facebook, or Print.
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Delivery Format:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    PDF or Video Depending upon the design you have choosen
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Changes:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    Please fill your details carefully. But in case if you want to make changes, after submit & Payment you will be allowed to make changes 1 time.
                  </td>
                </tr>
                <tr style={{ borderBottom: '1px solid #ddd' }}>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'left',
                      fontWeight: 'bold',
                      borderRight: '1px solid #ddd',
                    }}
                  >
                    Payment Method:
                  </td>
                  <td style={{ padding: '8px', textAlign: 'left' }}>
                    You can make payment via Paytm, Google Pay, PhonePe, UPI, Debit Card, Credit Card, Net Banking, UPI, etc.
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>

          {/* More Cards Section */}
          <Box>
            <Typography variant="h6" gutterBottom>
              People also like
            </Typography>
            <Grid container spacing={2}>
              {cardSuggestion?.rows?.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  {card?.card_samples?.map((img, id) => (
                    <Card key={id} sx={{ maxWidth: 345, boxShadow: 0, backgroundColor: isDarkMode ? 'black' : '#f5f5f5' }}>
                      <CardMedia
                        component="img"
                        height="150"
                        image={img.url}
                        alt={img.thumbnail}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="body1" component="div">
                          {card.card_name}
                        </Typography>
                        <Rating value={card.card_rating} readOnly />
                      </CardContent>
                    </Card>
                  ))}

                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Dialog open={payNowOpen} onClose={handleClosePayNowDialog}>
        <DialogTitle>Submission Successful</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your request for E-invite {params.group_type}  has been successfully submitted. Please pay  ₹{cardDetails?.sale_price} to get a E-invite {params.group_type}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePayNowDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePayNow} color="primary" variant="contained">
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InvitationCard;
